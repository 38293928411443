<template>
    <div>
        <div class="text-xs text-center text-white bg-hy-darkblue">
            <p class="py-4">
                Website maintained by Activa Media. All Rights Reserved.
            </p>
        </div>

    </div>  
</template>

<script>
export default {

}
</script>

<style>

</style>
